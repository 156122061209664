import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  addIcon: {
    // marginRight: theme.spacing(1),
  },
  
  mainContainer: {
    
    flexShrink: 0,
    overflow: 'auto',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 88px)',
      // maxHeight: 'calc(100vh - 165px)',
    },
  }
}));

export default useStyles;