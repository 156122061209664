import React, { useState, useEffect } from 'react';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import PageNavigation from 'components/CustomComponents/Navbars/ActivityNavigation'

//import style
import 'assets/css/loader.css';

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";

const DocView = (props) => {
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState('block')

    const hideSpinner = () => {
        setLoading(false);
        setDisplay('block');
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(1)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('setDeviceOrientation', 1, '');
        }
    };

    useEffect(() => {
        window.addEventListener("message", eventListener);
        return () => {
            window.removeEventListener("message", eventListener);
        };
    }, []);


    const eventListener = (event) => {
        if (event.data !== null && typeof event.data !== 'undefined') {
            console.log(event.data);
            try {
                let actObj = JSON.parse(event.data);
                if (actObj.Method == 'Back') {
                    if (typeof window.Android !== 'undefined') {
                        window.Android.setDeviceOrientation(2)
                    } else if (typeof window.flutter_inappwebview !== 'undefined') {
                        window.flutter_inappwebview.callHandler('setDeviceOrientation', 2, '');
                    }
                    return props.back ? props.history.push(props.back) : props.history.goBack()
                } else if (actObj.Method == 'FinishActivity') {
                    handlePostContentAccess(actObj.TrackingData.ConsumedTime)
                }
            } catch (error) {

            }

        }
    }


    const handlePostContentAccess = (accessTime) => {
        const BODY_DATA = {
            "missionActivityId": "", // Don't set this
            "userId": props.userInfo.userId,
            "locationId": props.userInfo.locationId,
            "groupId": props.userInfo.groupId,
            "questId": props.quest.questId,
            "contentId": props.content.contentId,
            "contentTypeCode": props.content.contentTypeCode,
            "accessDate": "" + CURRENT_DATE_TIME(),
            "accessDuration": "" + accessTime,
            "missionActivitySummaryId": "" // Don't set this
        }
        props.onPostContentAccess(BODY_DATA,props.content)
    }


    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.content.contentDisplayName} />

            {/*-----page-container----- */}
            <div className='page-container-5015_Activity'>
                {loading ? (
                    <div style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div className="loader">Loading...</div>
                    </div>

                ) : null}

                {props.content.contentTypeCode === '10'
                    ? <iframe
                        title="HTMLViewer"
                        style={{
                            position: 'relative',
                            left: 0,
                            // bottom: 0,
                            right: 0,
                            width: '100%',
                            minHeight: '100vh',
                            border: "none",
                            display: display
                        }}
                        onLoad={hideSpinner}
                        src={props.content.fileUrl}
                    />
                    : null
                }

            </div>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPostContentAccess: (accessData, content) => dispatch(questActions.postContentAccess(accessData, content)), //also update update content,current_cuntent and quest_progress 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocView);

