import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// material-ui components
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
// custom style
import styles from "assets/jss/components/views/signinStyle";

//axios
import axiosConfig from "util/axios";

// StoreUserData
import { StoreUserData } from 'util/commonfunctions'

// img
import QuestLogo from "assets/img/icons/QuestLogo.png";

// Views
// import SigninComponent from 'components/Views/SignIn/SigninMobile/SigninComponent'
import SigninComponent from 'components/Views/SignIn/SigninPromocode/PageContainer'
import Progress from "components/CustomComponents/Progress/Progress"
import { HIDE_FOR_IOS } from 'variables/general'

class SignIn extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      actionType: null,
      remember: true,
      userId: '',
      isLoading: false
    }
  }



  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userName = params.get('userName');
    const appId = params.get('AppId')

    if (appId !== null || appId !== '')
      sessionStorage.setItem('AppId', appId);


    if (typeof window.Android !== 'undefined' || typeof onRemenberMeClicked !== 'undefined') {
      const password = params.get('password');
      const isAutologin = params.get('isAutologin')
      const actionType = params.get('actionType')

      if (actionType !== null || actionType !== '')
        this.setState({ actionType: actionType })

      if (userName) {
        await this.setState({
          userName: userName,
          password: password,
          remember: true,
        })
        if (isAutologin) {
          this.setState({
            openProgress: true,
            isLoading: true,
          }, () => this.logIN())
        }
      }
    }

    if (localStorage.getItem('remember') === 'true') {
      this.setState({
        userName: localStorage.getItem('userName'),
        password: localStorage.getItem('password'),
        remember: true,
        isLoading: true
      }, () => this.logIN())
    } else if (sessionStorage.getItem('token')) {
      this.navigateTo()
    }

    if (this.props.configs.tcUrl === "")
      this.props.initAppConfigs()
  }



  async logIN() {
    axiosConfig().post('/QuestUser/AuthenticateUser', {}, {
      auth: {
        username: this.state.userName,
        password: this.state.password
      }
    }).then(async response => {
      this.props.setAuthError(false)
      if (this.state.remember) {
        await StoreUserData(this.state.userName, this.state.password, true)
      }
      sessionStorage.setItem('token', response.data.token);

      if (response.data.userType === "NewUser") {
        this.setState({ userId: response.data.userId, isLoading: false })
        //this.props.history.push({ pathname: '/', state: { userId: response.data.userId } });
        this.props.setUserType("NewUser");
      }

      else
        await axiosConfig().get('/QuestUser/GetCurrentUser').then(async response => {
          this.setState({ openProgress: false })
          this.props.setUserInfo(response.data)
          if (response.data.userQuestList.length > 0) {
            await this.props.initUserQuestList()
            this.setState({ isLoading: false }, () => this.navigateTo())
          } else if (response.data.promoCode) {
            const isRaymondUser = await questActions.verifyRaymondStoreCode(response.data.promoCode)
            if (isRaymondUser)
              this.setState({ isLoading: false }, () => this.props.history.push('/raymond-products'))
            else
              this.setState({ isLoading: false }, () => response.data.showDashboard ? this.navigateTo(true) : this.navigateTo())
          } else if (response.data.showDashboard) {
            this.setState({ isLoading: false }, () => this.navigateTo(true))
          } else {
            this.setState({ isLoading: false }, () => this.navigateTo())
          }
        }).catch(function (error) {
          this.setState({ openProgress: false })
          this.props.fetchUserInfoFailed()
        });

    }).catch((error) => {
      localStorage.clear();
      sessionStorage.clear();
      this.setState({ openProgress: false, isLoading: false })
      if (error.response) {
        this.props.setAuthError(true)
        this.setState({
          open: true,
          errorMessage: error.response.data.error
        })
      }
    });
  }

  // navigate To
  navigateTo(showDashboard = false) {
    if (showDashboard && this.props.apptype !== 'Spectrum' && localStorage.getItem('AppType') !== 'Spectrum') {
      return this.props.history.push('/products')
    } else if (this.state.actionType !== null && this.state.actionType !== '') {
      if (this.state.actionType === 'schedule')
        return this.props.history.push('/home/connect')
      return this.props.history.push('/home')
    }
    return this.props.history.push('/home')
  }




  render() {
    const { classes } = this.props;
    // Redirect To Spectrum Login
    //(this.props.platform === "IOS" && HIDE_FOR_IOS) ||
    if (this.props.apptype === 'Spectrum') {
      return (<Redirect push to="/signin" />)
    }

    if (this.state.isLoading) {
      return (
        <div className='signin-container background-signin'>
          <CssBaseline />
          <Progress open={this.state.isLoading} />
        </div>
      )
    }

    return (
      <React.Fragment >
        {/* <div>
          {this.props.platform}
        </div> */}



        <div className='signin-container background-signin' style={{ overflow: 'auto' }}>
          <CssBaseline />

          <Progress open={this.state.isLoading} />

          <main className={classes.layout} style={{
            paddingBottom: '2rem',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column',
            textAlign: 'center',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              <img className={classes.imageStyle} src={QuestLogo} alt="..." />
              <Paper className={classes.signinPaperContainer}>
                <SigninComponent
                  textColor="white"
                  userInfo={{ ...this.state }}
                />
              </Paper>
            </div>


            <Button
              round
              fullWidth
              variant="contained"
              color="info"
              className={classes.submit1}
              onClick={() => {
                this.props.history.push({
                  pathname: '/signin',
                  search: `${this.state.actionType !== null ? "?actionType=" + this.state.actionType : ''}`
                })
              }}
            >
              Spectrum Login {this.props.platform}
            </Button>

          </main>
        </div >
      </React.Fragment >
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.appconfigs.loading,
    configs: state.appconfigs.configs,
    apptype: state.appconfigs.apptype,
    platform: state.platforms.platform,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    //onInitAppConfigs: () => dispatch(questActions.initAppConfigs()),
    setAuthError: (auth) => dispatch(questActions.setAuthError(auth)),
    setUserType: (UserType) => dispatch(questActions.setUserType(UserType)),

    initAppConfigs: () => dispatch(questActions.initAppConfigs()),
    setUserInfo: (data) => dispatch(questActions.setUserInfo(data)),
    initUserQuestList: () => dispatch(questActions.initUserQuestList()),
    fetchUserInfoFailed: () => dispatch(questActions.fetchUserInfoFailed()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SignIn)))

