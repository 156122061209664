import React,{useEffect} from 'react';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";

const DocView = (props) => {

  


    useEffect(() => {
        let accessTime=0;
        const timer = setInterval(() => {
            accessTime++
        }, 1000);
        return () =>{
            if(accessTime>10)
            {
                const BODY_DATA = {
                    "missionActivityId": "", // Don't set this
                    "userId": props.userInfo.userId,
                    "locationId": props.userInfo.locationId,
                    "groupId": props.userInfo.groupId,
                    "questId": props.quest.questId,
                    "contentId": props.content.contentId,
                    "contentTypeCode": props.content.contentTypeCode,
                    "accessDate": "" + CURRENT_DATE_TIME(),
                    "accessDuration": "" + accessTime,
                    "missionActivitySummaryId": "" // Don't set this
                }
                props.onPostContentAccess(BODY_DATA,props.content)
            }
        
            clearTimeout(timer)};
      }, []);


    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.content.contentDisplayName}/>

            {/*-----html-container----- */}
            <div className='html-container'>
                <div >
                    <div
                        tyle={{
                            position: 'absolute',
                            left: 0,
                            // bottom: 0,
                            right: 0,
                            width: '100%',
                            minHeight: '100vh',
                            border: "none"
                        }}
                        dangerouslySetInnerHTML={{
                            __html: props.content.htmlContent
                        }} />
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPostContentAccess: (accessData, content) => dispatch(questActions.postContentAccess(accessData, content)), //also update update content,current_cuntent and quest_progress
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocView);

