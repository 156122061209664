import {
  dangerColor,
  whiteColor,
  roseColor,
  boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
//.....styles..........

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    display: 'flex',
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: 10,
    ...boxShadow
  },
  listGridContainer: {
    display: 'flex',
    justifyContent: "center"
  },

  buttonKeepShop: {
    width: '50%',
    alignSelf: 'center'
  },

  cardMedia: {
    width: 135
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },

  nameTitle: {
    display: 'block!important',
    display: '-webkit-box!important',
    '-webkit-line-clamp': 2,
    '-moz-line-clamp': 2,
    '-ms-line-clamp': 2,
    '-o-line-clamp': 2,
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    '-moz-box-orient': 'vertical',
    '-ms-box-orient': 'vertical',
    '-o-box-orient': 'vertical',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    color: '#29303b',
    lineHeight: 1.2,
    fontWeight: 700
  },
  lengthTitle: {
    color: whiteColor,
    padding: 5
  },
  priceTitle: {
    color: roseColor[0],
    fontWeight: 700,
    display: 'flex',
    alignItems: 'flex-end',
    float: 'right'
  },

  root: {
    flexGrow: 1,
    padding: 5
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // maxWidth: 500,
  },
  image: {
    // maxWidth: 250,
    // height: 150,
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
  },

  checkoutCardContainer: {
    flexGrow: 1,
    padding: 5,
    display:'flex',
    justifyContent:"flex-end"
  },

  checkoutCard: {
    flexGrow: 1,
    padding: 5
  },


  applyCouponButtonContainer: {
    // width: '20%',
    display: 'flex',
    // paddingTop: 2
    padding:0,
  },

  applyCouponTextContainer:{
    padding:0
  },

  applyCouponTextInput:{
    height:'0.8em'
  },
  applyCouponInputLabel:{
    fontSize:'0.8rem'
  },
  paperDialog: {
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
    borderRadius: 10
    }
  },

  backButtonContainer: {
    marginLeft: -10,
    flexDirection: "row",
    display: "flex",
    alignItems: 'center',
    color: "#fff"
  },

  gstFontStyle:{
    fontSize: '0.65rem',
    lineHeight: 1,
    fontWeight: 'bold'
  }

}));

export default useStyles;