
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

// @material-ui/core
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

//@material-ui/icons
import SendIcon from '@material-ui/icons/Send';
import AttachmentIcon from '@material-ui/icons/Attachment';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1.5),
        paddingTop: 5,
        paddingBottom: 5,
        color: '#fff',
        backgroundImage: 'linear-gradient(to right, #006c64 , #00b8ac)',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
    },
    saveButton: {
        color: "#fff"
    },
    input: {
        display: 'none',
      },

});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, onSave, onAttachment, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <IconButton aria-label="close" style={{ color: "#fff" }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="body1">{children}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    {onAttachment ? (
                        <div>
                            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onAttachment} />
                            <label htmlFor="icon-button-file">
                                <IconButton component="span"  className={classes.saveButton}>
                                    <Badge badgeContent={4} color="error" >
                                        <AttachmentIcon />
                                    </Badge>
                                </IconButton>
                            </label>
                        </div>

                    ) : null}
                    {onSave ? (
                        <IconButton className={classes.saveButton} onClick={onClose}>
                            <SendIcon />
                        </IconButton>
                    ) : null}

                </div>


            </div>
        </MuiDialogTitle>
    );
});

export default DialogTitle;
