import {  grayColor, blackColor,whiteColor } from "assets/jss/material-dashboard-react.js";

const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    // display: "none !important"
    //backgroundColor: primaryColor[0],
    height:0
  },
  tabsRoot: {
    minHeight: "unset !important",
    overflowX: "visible",
    "& $tabRootButton": {
      fontSize: "0.875rem"
    }
  },
  tabRootButton: {
    flexGrow: 1,
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    // borderRadius: "10px",
    // lineHeight: "24px",
    border: "0 !important",
    color:  "#000 !important",
    // color: 'rgb(182, 188, 226)' + " !important",
    // fontWeight: 500,
    transition: 'all .3s',
    backgroundColor: grayColor[5],
    // marginLeft: "4px",
    // "&:last-child": {
    //   marginLeft: "0px"
    // }
  },
  tabSelected: {
    transition: 'all .3s',
    backgroundColor: '#f16c2d',
    color: "#fff !important",
    // boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)'
    // transition: "0.2s background-color 0.1s"
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    textTransform: 'none',
    fontWeight: "bold",
    fontSize: "12px",
    marginTop: "1px",
    "& > svg,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important"
    }
  }
};

export default customTabsStyle;
