import React from "react";

//Redux
import {  useSelector } from 'react-redux'

// Components
import Pagination from '@material-ui/lab/Pagination';

//Custom Components
import AssignmentCard from "components/Views/AssessmentTests/components/AssignmentCard";

//Style
import useStyles from './styles.js'

const OnGoingTest = (props) => {
    const { loading, fetchAssessments,tabName } = props
    const Test = useSelector(state => state.onlineassignment.tests)
    const classes = useStyles();
    const [page, setPage] = React.useState(1);


    // handle Page Change
    const handlePageChange = (event, value) => {
        setPage(value);
        fetchAssessments(tabName, value)
    };


    return (
        <div className={classes.mainContainer}>
            <AssignmentCard
                data={Test.testList}
                onClickRow={() => { }}
                loading={loading}
                noDataMessage={"No data available"}
                type={tabName}
            />
            <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                <Pagination
                    count={Test.maxPageCount}
                    page={page}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default OnGoingTest;