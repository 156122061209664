import * as actionTypes from '../actionTypes'

const initialState = {
    tests: {
        maxPageCount: 0,
        testList: [],
        totalRecords: 0
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ONLINE_ASSIGNMENT:
            return {
                ...state,
                tests: action.payload
            }

        default:
            return state;
    }

}


export default reducer