import React from 'react';
// nodejs library to set properties for components
import moment from 'moment'
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
//@material-ui/core
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// core components
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";
// custom style
import useStyles from "./styles";

//Icon
import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';



const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
            <Card className={classes.cardStyle} key={v}>
                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        )}
    </React.Fragment>
    )
}



const AssignmentCard = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const { loading, data, noDataMessage, type } = props;


    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }
    const JoinButton = ({ currentData }) => {
        console.log(currentData.isViewDiagnosticReport);
        const handleClickStartTest = () => {
            let token = sessionStorage.getItem('token');
            let MLink = window.ONLINE_ASSESSMENT_URL + '?tk=' + token + '&osaid=' + currentData.onlineStudentAssignmentId + '&type=web'
            history.push('/online-test', { testLink: MLink, testName: currentData.paperName })
        }

        const handleClickViewSolution = () => {
            let token = sessionStorage.getItem('token');
            let MLink = window.VIEW_SOLUTION_URL + '?tk=' + token + '&osaid=' + currentData.onlineStudentAssignmentId + '&attemptid=' + currentData.attemptList[0].onlineStudentAssignmentAttemptId + '&type=web'
            history.push('/online-test', { testLink: MLink, testName: currentData.paperName })
        }

        if (type === 1 || type === 't3') {
            return (<Button size='sm' color="primary" onClick={handleClickStartTest}>Start Test</Button>)
        }

        if (type === 2) {
            return (<Button size='sm' color="info">Upcoming</Button>)
        }

        if (type === 3) {
            return (<React.Fragment>
                {currentData.isViewSolution && currentData.attemptList.length
                    ? <Button size='sm' color="facebook" onClick={handleClickViewSolution}>View Solution</Button>
                    : null
                }
            </React.Fragment>)
        }

        return (null)
    }

    const handleClickViewReport = (currentData) => {
        let token = sessionStorage.getItem('token');
        let MLink = window.DIAGNOSTIC_REPORT_URL + '?tk=' + token + '&osaid=' + currentData.onlineStudentAssignmentId + '&type=web'
        history.push('/online-test', { testLink: MLink, testName: currentData.paperName })
    }

    const handleDownloadReport = (fileUrl) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.downloadFile(fileUrl);
        } else {
            fetch(fileUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let objectURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = objectURL;
                    document.body.appendChild(link);
                    link.setAttribute('download', 'Report.pdf');
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    // Remove link from body
                    document.body.removeChild(link);
                });
        }
    }


    return (
        <React.Fragment>
            <GridContainer className={classes.gridContainerStyle}>
                <GridItem xs={12} sm={12} md={12} className={classes.gridItemStyle}>
                    {data.length ?
                        data.map((assessment, idex) => (
                            <Card key={idex + "CARD"} className={classes.cardStyle}>

                                <CardContent className={classes.cardContent}>
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <Typography variant="subtitle1" className={classes.paperName}>
                                            {assessment.paperName}
                                        </Typography>
                                        <Typography variant="caption" className={classes.senderName} gutterBottom >
                                            {assessment.testTypeName + " | " + assessment.totalQuestions + " Question" + " | " + assessment.totalMarks + " Marks"}
                                        </Typography>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '20rem', paddingTop: 5 }}>
                                            <div className={classes.icon_text}>
                                                <TodayIcon className={classes.iconTime} />
                                                <Typography variant="caption" className={classes.senderName}>
                                                    {` ${moment(assessment.testAssignStartDate).format('DD-MM-YYYY')}`}
                                                </Typography>
                                            </div>

                                            <div className={classes.icon_text}>
                                                <AccessTimeIcon className={classes.iconTime} />
                                                <Typography variant="caption" className={classes.senderName}>
                                                    {`${moment(assessment.testAssignStartDate).format('hh:mm:ss a')}`}
                                                </Typography>
                                            </div>

                                            <div className={classes.icon_text}>
                                                <TimelapseIcon className={classes.iconTime} />
                                                <Typography variant="caption" className={classes.senderName}>
                                                    {`${assessment.testDuration}min`}
                                                </Typography>
                                            </div>
                                        </div>


                                    </div>
                                </CardContent>

                                <CardActions>
                                    <JoinButton currentData={assessment} />
                                    {assessment.isViewDiagnosticReport && assessment.attemptList.length > 0
                                        && <Button
                                            size='sm'
                                            color="info"
                                            onClick={() => handleClickViewReport(assessment)}>View Report</Button>
                                    }
                                    {assessment.isReportGenerated && assessment.attemptList.length > 0
                                        && <Button
                                            size='sm'
                                            color="info"
                                            onClick={() => handleDownloadReport(assessment.reportPath)}>Download Report</Button>
                                    }
                                </CardActions >
                            </Card>
                        ))
                        : <Card className={classes.cardStyle}>
                            <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                                <Typography variant="body1" color="textSecondary">
                                    {noDataMessage}
                                </Typography>

                            </CardContent>
                        </Card>
                    }
                </GridItem>
            </GridContainer>
        </React.Fragment>
    );
}

AssignmentCard.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    noDataMessage: PropTypes.string,
    onClickRow: PropTypes.func
};

export default AssignmentCard;