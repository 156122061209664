import React from 'react';
import PropTypes from 'prop-types';

// material-ui components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import SwipeableViews from 'react-swipeable-views';

import styles from "./styles";
import { Divider } from '@material-ui/core';
const useStyles = makeStyles(styles);

const NavTab = (props) => {
  const { plainTabs, tabs, onTabChange } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    setValue(value);
    onTabChange({
      name: tabs[value].tabName,
      icon: tabs[value].tabIcon
    })
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <React.Fragment >


      <Tabs
        variant="scrollable"
        // scrollButtons="on"
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.displayNone,
          // scrollButtons: classes.displayNone
        }}
      >
        {tabs.map((prop, key) => {
          var icon = {};
          if (prop.tabIcon) {
            icon = {
              icon: <prop.tabIcon />
            };
          }
          return (
            <Tab
              classes={{
                root: classes.tabRootButton,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper
              }}
              key={key}
              label={prop.tabName}
              {...icon}
            />
          );
        })}
      </Tabs>
      <Divider></Divider>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key} >{prop.tabContent}</div>;
          }
          return <React.Fragment key={key}></React.Fragment>;
        })}
      </SwipeableViews>

    </React.Fragment>
  );
}

NavTab.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

export default NavTab;