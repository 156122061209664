import React, { useEffect } from "react";
//Redux
import { connect } from 'react-redux'

// @material-ui/core
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';


// @material-ui/icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ListAltIcon from '@material-ui/icons/ListAlt';
import WebIcon from '@material-ui/icons/Web';
import CasinoIcon from '@material-ui/icons/Casino';


//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import LinearProgressWithLabel from 'components/CustomComponents/Progress/LinearProgressWithLabel'
import CircularProgressWithLabel from 'components/CustomComponents/Progress/CircularProgressWithLabel'

// styles
import useStyles from "assets/jss/components/views/taskStyle";
import '../style.css'

//import reducer 
import * as questActions from 'store/actions/index'


const OpCardContent = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.contentText1} style={{ backgroundColor: props.bgcolor }}>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center',paddingBottom:2 }}>
                <div className={classes.countCardIcon}>
                    {props.icon}
                </div>
                <Typography variant="caption" className={classes.countCardText}>
                    {props.countText}
                </Typography>
            </div>
            <Typography variant="caption" className={classes.countCardTitle}>
                {props.title}
            </Typography>
        </div>
    )
}

const ChapterCards = (props) => {

    ///const contentList =getSteps()
    const classes = useStyles();
    // const [activeCard, setActiveCard] = React.useState(0);


    useEffect(() => {
        if (props.operation.missionId !== props.mission.missionId) {
            props.setCurrentOperation()
        }
    }, [props.mission])



    const handleCardSelect = (operation) => {
        props.setCurrentOperation(operation)
        props.onClick(operation);
    }

    // useEffect(() => { window.scrollTo(0, 0) }, [props.operation.operationId])


    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12} >
                <div> </div>
                {props.questContent.add.operations.filter(current_value => props.mission.missionId === current_value.missionId).map((operation, index) => (
                    <Card key={operation.operationId} className={operation.operationId === props.operation.operationId ? classes.activeCard : classes.deactivateCard} style={{ cursor: 'pointer' }} onClick={() => handleCardSelect(operation)}>

                        <CardHeader
                            style={{ paddingBottom: 5, paddingTop: 10 }}
                            title={operation.sequenceNo + '.' + operation.operationDisplayName}
                            titleTypographyProps={{ variant: 'body1', className: classes.operationCardTitle }}
                        >
                        </CardHeader>

                        <CardContent classes={{ root: classes.operationContentCard }}>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>

                                {operation.videoCount ?
                                    <OpCardContent
                                        countText={`${operation.progress.avCount}/${operation.videoCount}`}
                                        title='Watch'
                                        icon={<VideoLibraryIcon className={classes.countIcon} />}
                                        bgcolor='#67bc47'
                                    />
                                    : null
                                }

                                {operation.testCount ?
                                    <OpCardContent
                                        countText={`${operation.progress.testCount}/${operation.testCount}`}
                                        title='Think'
                                        icon={<ListAltIcon className={classes.countIcon} />}
                                        bgcolor='#f26c2c'
                                    />
                                    : null
                                }

                                {operation.webContentCount ?
                                    <OpCardContent
                                        countText={`${operation.progress.webContentCount}/${operation.webContentCount}`}
                                        title='Solve'
                                        icon={<WebIcon className={classes.countIcon} />}
                                        bgcolor='#124989'
                                    />
                                    : null
                                }

                                {operation.activityCount ?
                                    <OpCardContent
                                        countText={`${operation.progress.activityCount}/${operation.activityCount}`}
                                        title='Play'
                                        icon={<CasinoIcon className={classes.countIcon} />}
                                        bgcolor='#00b8ac'
                                    />
                                    : null
                                }

                            </div>
                        </CardContent>
                        <CardContent style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <LinearProgressWithLabel
                                style={{ height: 7, borderRadius: 50 }}
                                value={operation.progress.progress}
                                lableBoxStyle={{ minWidth: 'auto' }}
                            />
                        </CardContent>

                    </Card>))}
            </GridItem>
        </GridContainer >
    )
}


const mapStateToProps = state => {
    return {
        questContent: state.content.contentlist,
        mission: state.content.current_mission,
        operation: state.content.current_operation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOperation: (operation) => dispatch(questActions.setCurrentOperation(operation))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ChapterCards);




