
//--- QA Server -----------------
export const serverURL = `https://devapi.questplus.in/`;
export const NexoolURL = `http://test.nexool.com:4005/thirdpartyauth`
export const LastUrl='http://devweb.questplus.in/home'

//--- Production Server ----------
 //export const serverURL = `https://api.questplus.in/`;
 //export const NexoolURL = `https://app2.nexool.com/thirdpartyauth`
 //export const LastUrl='http://web.questplus.in/home'
