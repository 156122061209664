import * as actionTypes from '../actionTypes';
import axiosConfig from "util/axios";

//---Todays Online Classes---
export const setOnlineAssignment = (testList) => {
    return {
        type: actionTypes.SET_ONLINE_ASSIGNMENT,
        payload: testList
    }
}

export const fetchOnlineAssignment = (type, PageNumber, PageSize) => {
    return async (dispatch, getState) => {
        await axiosConfig().post('/OnlineTest/GetTestList', {
            "type": type, // type==0 -- ALL Test, type==1 -- OnGoing Test, type==2 -- UpComing Test,type==3 -- Missed Test
            "testTypeId": "0",  //--1 = Objective & 2 = Subjective / Pass "0" for all
            "subjectId": 0,
            "pageNumber": PageNumber,
            "pageSize": PageSize
        }).then(response => {
            // console.log(response.data);
            dispatch(setOnlineAssignment(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

// old code
// export const fetchOnlineAssignment = (TabType, PageNumber = 1, PageSize = 10) => {
//     return async (dispatch, getState) => {
//         const UserId = getState().user.userinfo.UserId
//         await axiosConfig().get('/schedule/GetTestSchedule', {
//             params: {
//                 UserId: UserId,
//                 TabType: TabType,
//                 PageNumber: PageNumber,
//                 PageSize: PageSize
//             }
//         }).then(response => {
//             dispatch(setOnlineAssignment(response.data))
//         }).catch(function (error) {
//             if (error.response.data)
//                 throw new Error(error.response.data.error)
//             throw new Error(error.message);
//         });
//     }
// }