
import { roundPercentageNumber } from 'util/commonfunctions';


const afterTickToLabelConversion = (scaleInstance) => {
    // set the first and last tick to null so it does not display
    // note, ticks[0] is the last tick and ticks[length - 1] is the first
    scaleInstance.ticks[0] = null;
    scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

    // need to do the same thing for this similiar array which is used internally
    scaleInstance.ticksAsNumbers[0] = null;
    scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
}


const groupedBarchartOptions = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    scales: {
        xAxes: [{
            ticks: {
                callback: function (tick) {
                    var characterLimit = 20;
                    if (tick.length >= characterLimit) {
                        return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + '...';;
                    }
                    return tick;
                }
            }
        }],
        yAxes: [{
            display: true,
            afterTickToLabelConversion: afterTickToLabelConversion,
            scaleLabel: {
                display: true,
                labelString: 'Completed(%)'
            },
            ticks: {
                beginAtZero: true,
                steps: 10,
                stepSize:20,
                max: 110
            }
        }]
    },
    plugins: {
        labels: {
            fontSize:0,
            render: 'value',
            // fontStyle: 'bold',
            showZero: false,
        
        }
    }
}


const barchartOptions = {
    responsive: true,
    legend: {
        display: false,
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    scales: {
        yAxes: [{
            display: true,
            afterTickToLabelConversion: afterTickToLabelConversion,
            scaleLabel: {
                display: true,
                labelString: 'Completed(%)'
            },
            ticks: {
                beginAtZero: true,
                steps: 10,
                // stepValue: 5,
                stepSize: 20,
                max: 110
            }
        }]
    },
    plugins: {
        labels: {
            fontSize:0,
            render: 'value',
            fontStyle: 'bold',
            showZero: false,
        }
    }
}

const piechartOptions = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    plugins: {
        labels: {
            render: 'percentage',
            precision: 2,
            fontStyle: 'bold',
        }
    }
}

//labels: ['AV', 'Test', 'Web', 'Activity'],
const getQuestBarchart=async (questContent,questProgress)=>{
    const videoCount = await questContent.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.videoCount, 0)
    const testCount = await questContent.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.testCount, 0)
    const activityCount = await questContent.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.activityCount, 0)
    const webContentCount = await questContent.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.webContentCount, 0)
  
    const progressVideoCount = roundPercentageNumber(questProgress.avCount, videoCount)
    const progressTestCount = roundPercentageNumber(questProgress.testCount, testCount)
    const progressActivityCount = roundPercentageNumber(questProgress.activityCount, activityCount)
    const progressWebContentCount = roundPercentageNumber(questProgress.webContentCount, webContentCount)
  
    return {
      datasets: [{
        data: [progressVideoCount, progressTestCount, progressWebContentCount, progressActivityCount],
        backgroundColor: ['#89CDDF', '#AF65DF', '#19D4A7', '#E98B4D']
      }],
      labels: ['Watch', 'Think', 'Solve', 'Play'],
    };
    
}

/**
 * Custom function that prepare grouped chart data.
 * @param dataArray:Array.
 * @param displayName:String.
 * @param idName:String.
 * @returns {Number}
 */
const groupedChartData = async (dataArray, displayName, idName) => {
    let chartData = {
        labels: [],
        datasets: [
            {
                ids: [], label: "Watch", backgroundColor: "#89CDDF", data: []
            },
            {
                ids: [], label: "Think", backgroundColor: "#AF65DF", data: []
            },
            {
                ids: [], label: "Solve", backgroundColor: "#19D4A7", data: []
            },
            {
                ids: [], label: "Play",backgroundColor: "#E98B4D", data: []
            }
        ]
    };
    await dataArray.forEach(currentData => {

        chartData.labels.push(currentData[displayName])
        chartData.datasets.forEach(currentValue => {
            currentValue.ids.push({ 'label': currentData[displayName], 'id': currentData[idName] })
            if (currentValue.label === "Watch")
                currentValue.data.push(roundPercentageNumber(currentData.progress.avCount, currentData.videoCount))
            if (currentValue.label === "Think")
                currentValue.data.push(roundPercentageNumber(currentData.progress.testCount, currentData.testCount))
            if (currentValue.label === "Solve")
                currentValue.data.push(roundPercentageNumber(currentData.progress.webContentCount, currentData.webContentCount))
            if (currentValue.label === "Play")
            currentValue.data.push(roundPercentageNumber(currentData.progress.activityCount, currentData.activityCount))
        }
        )
    })
    return chartData;
}





export {
    barchartOptions,
    groupedBarchartOptions,
    piechartOptions,
    getQuestBarchart,
    groupedChartData,
}