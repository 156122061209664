import React, { useEffect, useState } from "react";

//Redux
import { connect } from 'react-redux'

import { Doughnut, Line } from 'react-chartjs-2';

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Body from "components/CustomComponents/Card/CardBody.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";


// styles
import Styles from "assets/jss/components/views/assessmentStyle";
const useStyles = makeStyles(Styles);


const afterTickToLabelConversion = (scaleInstance) => {
    // set the first and last tick to null so it does not display
    // note, ticks[0] is the last tick and ticks[length - 1] is the first
    scaleInstance.ticks[0] = null;
    scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

    // need to do the same thing for this similiar array which is used internally
    scaleInstance.ticksAsNumbers[0] = null;
    scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
}


const barchartOptions = {
    responsive: true,
    legend: {
        display: false,
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    plugins: {
        labels: {
            fontSize: 0,
            render: 'value',
            fontStyle: 'bold',
            showZero: false,
        }
    }
}


const QuestionAnswers = (props) => {
    const classes = useStyles();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const skipCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isQuestionSkipped === "0" ? 1 : 0), 0)
    const rightCount = props.answers.reduce((Count, currentValue) => Count + parseInt(currentValue.isAnsweredCorrect), 0)
    const wrongCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isAnsweredCorrect === "0" && currentValue.isQuestionSkipped !== "0" ? 1 : 0), 0)
    const marksObtained = props.answers.reduce((Count, currentValue) => Count + parseFloat(currentValue.questionMarksObtained), 0)
    const data = {
        datasets: [{
            data: [skipCount, rightCount, wrongCount],
            backgroundColor: ['#A9A9A9', '#51D887', '#FF0000']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: ['Skip', 'Correct', 'Incorrect'],
    };

    const legend = {
        position: "bottom",
    }


    const [bestAttempt, setBestAttempt] = useState({})
    const [allAttempt, setAllAttempt] = useState({})
    useEffect(() => {
        console.log(props.testHistory);
        if (Object.keys(props.testHistory).length > 0) {
            let bestAttempt = props.testHistory.attemptList.filter(op => op.attemptNumber === props.testHistory.bestAttemptNumber).reduce((acc, cv) => { return acc = cv }, {})
            setBestAttempt(bestAttempt)
            let all_attempt = props.testHistory.attemptList.sort((a, b) => a.attemptNumber - b.attemptNumber);
            setAllAttempt({
                datasets: [{
                    data: all_attempt.map(data => {
                        return data.marksObtained
                    }),
                }],
                labels: all_attempt.map(data => {
                    return data.attemptNumber
                }),
            })
        }
    }, [props.testHistory])

    useEffect(() => {
        if (props.test.testAttemptedDuration > 0) {
            let totalSeconds = Number(props.test.testAttemptedDuration);
            totalSeconds %= 3600;
            const newMinutes = Math.floor(totalSeconds / 60);
            const newSeconds = totalSeconds % 60;
            setMinutes(newMinutes)
            setSeconds(newSeconds)
        }
    }, [])


    return (
        <React.Fragment>
            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                Current Attempt
            </Typography>
            <GridContainer>
                <GridItem xs={8} sm={8} md={8} className={classes.centerContainer}>
                    {props.loading
                        ? <Skeleton className={classes.centerContainer} animation="wave" variant="circle" width={'15rem'} height={'15rem'} />
                        : <Doughnut data={data} legend={legend} ></Doughnut>
                    }
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className={classes.centerContainer} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <div style={{ width: props.loading ? '100%' : "", display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Score : ${marksObtained + " / " + props.test.totalMark}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Correct : ${rightCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Incorrect : ${wrongCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Skipped : ${skipCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Attempts : ${props.content.progress ? props.content.progress.accessCount + 1 : 0}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Time Taken : ${minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds)} minutes`}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                Best Attempt
            </Typography>
            <GridContainer>
                <GridItem xs={8} sm={8} md={8} className={classes.centerContainer}>
                    {props.loading
                        ? <Skeleton className={classes.centerContainer} animation="wave" variant="circle" width={'15rem'} height={'15rem'} />
                        : <Doughnut
                            data={{
                                datasets: [{
                                    data: [
                                        bestAttempt.skipQuestionCount,
                                        bestAttempt.rightAnswerCount,
                                        bestAttempt.wrongAnswerCount
                                    ],
                                    backgroundColor: ['#A9A9A9', '#51D887', '#FF0000']
                                }],

                                // These labels appear in the legend and in the tooltips when hovering different arcs
                                labels: ['Skip', 'Correct', 'Incorrect'],
                            }}
                            legend={legend} ></Doughnut>
                    }
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className={classes.centerContainer} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <div style={{ width: props.loading ? '100%' : "", display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Score : ${bestAttempt.marksObtained + " / " + bestAttempt.totalMarks}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Correct : ${bestAttempt.rightAnswerCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Incorrect : ${bestAttempt.wrongAnswerCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Skipped : ${bestAttempt.skipQuestionCount}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Attempts : ${bestAttempt.attemptNumber}`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Time Taken : ${bestAttempt.totalTimeConsume} minutes`}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                Score-All Attempts
            </Typography>
            <GridContainer>
                <GridItem xs={12} sm={12} md={8} className={classes.centerContainer}>
                    <Line
                        data={allAttempt}
                        options={{...barchartOptions,
                            scales: {
                                yAxes: [{
                                    display: true,
                                    afterTickToLabelConversion: afterTickToLabelConversion,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Marks'
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        steps: 20,
                                        // stepValue: 5,
                                        stepSize: 1,
                                        max: props.test.totalMark+1
                                    }
                                }],
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Attempts'
                                    }
                                }]
                            },
                        }}
                    />
                </GridItem>
            </GridContainer>
        </React.Fragment >
    )
}


const mapSateToProps = state => {
    return {
        loading: state.questions.post_test_loading,
        content: state.content.current_content,
        test: state.content.current_test,
        testHistory: state.content.current_test_history,
        answers: state.questions.answers,
    }
}

const mapDispatchToProps = dispatch => {
    return {


    }
}


export default connect(mapSateToProps, mapDispatchToProps)(QuestionAnswers);
