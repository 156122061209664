import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//redux
import { connect, useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// axios
import axiosConfig from 'util/axios'

// @material-ui/core
import Backdrop from '@material-ui/core/Backdrop';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

// core components
import VideoPlayer from 'components/CustomComponents/VideoPlayer/VideoPlayer'
import Progress from "components/CustomComponents/Progress/Progress"
import Button from "components/CustomComponents/CustomButtons/Button"
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog"

//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/like';

//Style
import useStyles from 'assets/jss/components/views/videoViewerStyle'
//axios
import { Token } from "util/commonfunctions";

//variables
import {
    CURRENT_DATE_TIME,
    SHARE_URL_ANDROID,
    SHARE_URL_IOS
} from "variables/general.js";

const SkeletonComp = () => (<React.Fragment >
    <Skeleton animation="wave" variant="circle" width={25} height={25} />
    {/* <Skeleton animation="wave" variant="rect" width={45} height={25} /> */}
</React.Fragment>)

const FlashAnimation = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        // rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice"
        // }
    };
    const classes = useStyles();
    useEffect(() => {
        let myInterval = null;
        if (props.open)
            myInterval = setInterval(() => {
                props.close()
            }, 1000)

        return () => {
            clearInterval(myInterval);
        };
    }, [props.open]);

    return (
        <div>
            <Backdrop open={props.open} className={classes.backdrop}>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </Backdrop>
        </div>
    )
}


const ControlButton = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.divStyle} >
            {props.loading
                ? <div className={classes.controlSkeleton}> <SkeletonComp /></div>
                : <React.Fragment>
                    <div>
                        <IconButton className={classes.muiIconButton} onClick={() => props.onClick()} disabled={props.disabled}>
                            {typeof props.iconClass === 'string'
                                ? <Icon className={props.iconClass} fontSize="small" style={{ color: props.disabled ? '#C0C0C0' : props.color }} />
                                : props.iconClass
                            }

                        </IconButton>
                    </div>
                    <Typography variant="caption" style={{ color: '#000' }}>{props.label}</Typography>
                </React.Fragment>
            }
        </div>
    )
}


const VideoViewer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const [flashAnimation, setFlashAnimation] = useState(false)
    const [isIOS, setIOS] = useState(typeof onRemenberMeClicked)
    const [iosLoading, setIOSLoading] = useState(false)
    const [reatingPopup, setReatingPopup] = useState(false)
    const [showAssessment, setShowAssessment] = useState(false)
    const [accessDateTime,setAccessDateTime]=useState(CURRENT_DATE_TIME())


    // Props
    const { orientation, isVideo, onChangeOrientation, isWeb } = props



    /*---------------------init Content Rating--------------------*/
    useEffect(() => {
        if (props.content.contentId) {
            setShowAssessment(false)
            if (isIOS !== 'undefined') {
                initIOS()
            } else {
                props.onInitContentRating(props.content.contentId)
            }
        }
    }, props.content.contentId)

    /*--------------- Code for IOS------------------- */
    const initIOS = () => {
        setIOSLoading(true)
        axiosConfig().get('/QuestContent/GetContentRating', {
            params: {
                ContentId: props.content.contentId,
            }
        }).then(response => {
            setIOSLoading(false)
            dispatch(questActions.setContentRating(response.data))
            window.event = new Event('event');
            window.addEventListener('event', function (data) {
                WebViewEvent(data);
            }, false);
            const apptoken = Token();
            window.getHLSURL(props.content.fileUrl, response.data.likeCount, response.data.disLikeCount, response.data.myRating, props.content.contentId, apptoken);

        }).catch(function (error) {
            setIOSLoading(false)
            console.log(error);
        });
    }

    const WebViewEvent = (data) => {
        if (data.detail) {
            switch (data.detail.type) {
                case 'rating': {
                    handleContentRating(data.detail.rating)
                    break;
                }
                case 'back': {
                    handleIOSBack(data.detail.duration)
                    break;
                }
                default:
                    return null;
            }
        }
    }
    const handleIOSBack = (accessTime) => {
        if (accessTime > 1) {
            const BODY_DATA = {
                "missionActivityId": "", // Don't set this
                "userId": props.userInfo.userId,
                "locationId": props.userInfo.locationId,
                "groupId": props.userInfo.groupId,
                "questId": props.quest.questId,
                "contentId": props.content.contentId,
                "contentTypeCode": props.content.contentTypeCode,
                "accessDate": "" + accessDateTime,
                "accessDuration": "" + accessTime,
                "missionActivitySummaryId": "" // Don't set this
            }
            props.onPostContentAccess(BODY_DATA,props.content)
        }
        history.goBack()
    }
    const handleContentRating = (rating) => {
        setReatingPopup(false)
        if (rating !== props.ratings.myRating) {
            const BODY_DATA = {
                "userId": props.userInfo.userId,
                "contentId": props.content.contentId,
                "rating": "" + rating
            }
            props.onPostContentRating(BODY_DATA);
            if (rating > 0)
                setFlashAnimation(true)
        }
    }
    /*
    if (isIOS !== 'undefined') {
        return (null);
    }
    */
    if (isIOS !== 'undefined') {
        return (
            <>
                {iosLoading
                    ? <Progress open={iosLoading} />
                    : null
                }
            </>
        );
    }
    // handel Video Ended
    const handelVideoEnded = () => {
        if (props.ratings.myRating === undefined || props.ratings.myRating === null || props.ratings.myRating === "")
            setReatingPopup(true)
        if (props.content.testCode !== undefined && props.content.testCode !== '')
            setShowAssessment(true)
    }

    // handle Click Share
    const handleClickShare = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.shareContent(`Hi, I am studing ${props.content.contentDisplayName} from ${props.operation.operationDisplayName} on Singhania Quest+ App. The app has engauging  Academic and non-academic content. Please download the app from ${SHARE_URL_ANDROID(props.userInfo.userId)} and explore the App.`)
        } else if (typeof shareContent !== 'undefined') {
            window.shareContent(`Hi, I am studing ${props.content.contentDisplayName} from ${props.operation.operationDisplayName} on Singhania Quest+ App. The app has engauging  Academic and non-academic content. Please download the app from ${SHARE_URL_IOS()} and explore the App.`)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('shareContent', "Hi Quest");
        }
    }

    // handle Click Assessment
    const handleClickAssessment = () => {
        // let token = sessionStorage.getItem('token');
        // let MLink = ' https://devadmin.questplus.in/online-assessment?tk=' + token + '&osaid=' + props.content.onlineStudentAssignmentId + '&type=web'
        // history.push('/online-test', { testLink: MLink, testName: props.content.contentDisplayName })
        history.push('/instruction')
    }

    return (
        <div>
            <AlertDialog open={reatingPopup} type="like" cancelable={() => { }}>
                <DialogActions >
                    <Button color={"rose"} startIcon={<ThumbUpIcon />} onClick={() => handleContentRating(5)}>Like</Button>
                    <Button color={"warning"} startIcon={<ThumbDownIcon />} onClick={() => handleContentRating(0)}>Dislike</Button>
                </DialogActions>
            </AlertDialog>


            {flashAnimation ? <FlashAnimation open={flashAnimation} close={setFlashAnimation} ></FlashAnimation> : null}

            {/*-----page-container----- */}
            <div className={`${orientation === "portrait" ? 'video-container' : 'video-container-landscape'}`}>
                <div className={classes.centerContainer}>

                    <VideoPlayer
                        content={props.content}
                        isVideo={isVideo}
                        onChangeOrientation={onChangeOrientation}
                        orientation={orientation}
                        accessDateTime={accessDateTime}
                        onPlay={setAccessDateTime}
                        onEnded={handelVideoEnded}
                        isWeb={isWeb}
                    />
                    {(isVideo && orientation === "portrait")
                        ? <React.Fragment>
                            <Typography variant="body1" className={classes.titelContainer}>{props.content.contentDisplayName}</Typography>
                            <div className={classes.footerDivStyle}>

                                <ControlButton
                                    loading={props.loading}
                                    label=''
                                    iconClass='icon-ic-thumb-up-24px'
                                    color={props.ratings.myRating === 5 ? '#00B8AC' : '#362e2e'}
                                    onClick={() => handleContentRating(5)}
                                />
                                <ControlButton
                                    loading={props.loading}
                                    label=''
                                    iconClass='icon-ic-thumb-down-24px'
                                    color={props.ratings.myRating === 0 ? '#00B8AC' : '#362e2e'}
                                    onClick={() => handleContentRating(0)}
                                />
                                <ControlButton
                                    loading={false}
                                    disabled={true}
                                    label=''
                                    iconClass='icon-ic-share-24px'
                                    color={'#362e2e'}
                                    onClick={handleClickShare}
                                />
                                <ControlButton
                                    loading={false}
                                    disabled={!showAssessment}
                                    label=''
                                    iconClass='icon-component-1-2'
                                    color={'#362e2e'}
                                    onClick={handleClickAssessment}
                                />
                                <ControlButton
                                    loading={false}
                                    disabled={true}
                                    label=''
                                    iconClass='icon-ic-speaker-notes-24px'
                                    color={'#362e2e'}
                                    onClick={() => { }}
                                />
                            </div>
                        </React.Fragment>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        ratings: state.content.content_ratings,
        loading: state.content.content_ratings_loading,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
        operation: state.content.current_operation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitContentRating: (ContentId) => dispatch(questActions.initContentRating(ContentId)),
        onPostContentRating: (ratingData) => dispatch(questActions.postContentRating(ratingData)),
        onPostContentAccess: (accessData, content) => dispatch(questActions.postContentAccess(accessData, content))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewer);