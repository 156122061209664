import React, { useEffect, useState } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//@material-ui/core
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

//Custom Components
import ProductCard from './components/ProductCard'
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"

//commonfunctions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";

// custom style
import useStyles from "assets/jss/components/views/cartStyle";


const RaymondProducts = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const products = useSelector(state => state.raymond.products)
    const userInfo = useSelector(state => state.user.userinfo)

    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("danger");

    useEffect(() => {
        fetchProducts()
    }, [dispatch])


    /*-----------------------fetch raymond products --------------------*/
    const fetchProducts = async () => {
        setLoading(true)
        dispatch(questActions.fetchRaymondProducts()).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setAlertType("danger")
            setAlertMessage(error.message)
        })
    }


    //-----------------------Handel Buy Quest---------------------
    const onBuyQuest = async (product) => {
        //clevertap
        const eventData = JSON.stringify({
            'UserId': userInfo.userId,
            'ProductId': product.productId,
            'ProductName': product.productDisplayName
        });
        clevertapEvents('PurchaseInitiate', eventData);

        //facebookTracking
        const EventProperty = JSON.stringify({
            'fb_content_type': product.productDisplayName,
            'fb_content_id': product.productId,
            'fb_num_items': 1,
            'fb_payment_info_available': false,
            'fb_currency': ''
        });
        facebookTracking('fb_mobile_initiated_checkout', EventProperty)

        await dispatch(questActions.addToCart({
            "cartId": "",
            "userId": userInfo.userId,
            "productId": product.productId,
            "productName": product.productDisplayName,
            'productIcon': product.productIcon,
            "qty": 1,
            "price": Number(product.planPrice),
            "netPrice": Number(product.planPrice),
            "planId": product.planId
        }));

        
        props.history.push('/cart',{backTo:"/raymond-products"});
    }


    return (
        <div className='signin-container background-home'>
            <CssBaseline />
           

            <div style={{ padding: 30 }} >
                <GridContainer className={classes.listGridContainer}>
                    <GridItem xs={12} sm={12} md={8} >
                        <div className={classes.backButtonContainer}>
                            <Typography component="h1" variant="body1" color="inherit" noWrap style={{ fontWeight: 'bold' }} >
                                {"Product List"}
                            </Typography>
                        </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={8} style={{padding: 0}}>
                        <div className='scrollNotification'>  {/* style={{ height: '90vh', overflow: "overlay", }} */}
                            <ProductCard
                                data={products}
                                loading={loading}
                                onBuy={onBuyQuest}
                            />
                        </div>
                </GridItem>
                </GridContainer>


           
            </div>
        </div>
    )

}

export default RaymondProducts;