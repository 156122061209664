import React, { useRef, useEffect, useState } from "react";
//redux
import { connect } from 'react-redux'

// chart
import { Pie, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";

//Utility
import { roundPercentageNumber } from 'util/commonfunctions';
import {
  barchartOptions,
  groupedBarchartOptions,
  piechartOptions,
  getQuestBarchart,
  groupedChartData,
} from './DashboardUtility'
//style
import styles from "assets/jss/components/views/dashboardStyle";

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const classes = useStyles();
  const pieRef = useRef(null);
  const qustBarRef = useRef(null);
  const missionBarRef = useRef(null);
  const operationBarRef = useRef(null);

  const [qustPieData, setQuestPieData] = useState({});
  const [qustBarData, setQuestBarData] = useState({});
  const [missionBarData, setMissionBarData] = useState({})
  const [operationBarData, setOperationBarData] = useState({})

  useEffect(() => {
    populateQuestPie()
    populateQuestBar()
    missionProgress()
  }, [props.questContent])

  // ----------Populate Quest Pie--------
  const populateQuestPie = () => {
    const pieChartdata = {
      datasets: [{
        data: [Math.round(props.questProgress.progress), Math.round(100 - props.questProgress.progress)],
        backgroundColor: ['#B0D851', '#EC9B4A']
      }],
      labels: ['Completed', 'Remaining'],
    };
    setQuestPieData(pieChartdata)
  }

  // ----------populate Quest Bar--------
  const populateQuestBar = async () => {
    const questBarchartData = await getQuestBarchart(props.questContent, props.questProgress)
    await setQuestBarData(questBarchartData)
  }

  // ----------Mission Progress--------
  const missionProgress = async () => {
    const chartData = await groupedChartData(props.questContent.add.missions, 'missionDisplayName', 'missionId')
    setMissionBarData(chartData)

    //operationsProgress
    if (Object.keys(chartData).length) {
      if (chartData.datasets[0]) {
        if (chartData.datasets[0].ids[0])
          operationsProgress(chartData.datasets[0].ids[0].id)
      }
    }
  }

  const clickHandler = (evt) => {
    const activePoint = evt[0];
    if (activePoint) {
      const data = activePoint._chart.data;
      const datasetIndex = activePoint._datasetIndex;
      const id = data.datasets[datasetIndex].ids[activePoint._index].id;
      operationsProgress(id)
    }
  }
  // ----------Pperations Progress--------
  const operationsProgress = async (missionId) => {
    let chartData = await groupedChartData(
      props.questContent.add.operations.filter(current_value => current_value.missionId === missionId),
      'operationDisplayName',
      'operationId'
    )
    setOperationBarData(chartData)
  }

  return (
    <div className='page-container'>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader >
              <h4 className={classes.cardTitle}>Quest Progress</h4>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CardBody color="warning">
                  <Pie
                    data={{ ...qustPieData }}
                    options={piechartOptions}
                    ref={pieRef}
                  />
                </CardBody>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CardBody color="warning">
                  <Bar
                    data={{ ...qustBarData }}
                    options={barchartOptions}
                    ref={qustBarRef}
                  />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader >
              <h4 className={classes.cardTitle}>Mission</h4>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CardBody color="warning">
                  <Bar
                    data={{ ...missionBarData }}
                    options={groupedBarchartOptions}
                    ref={missionBarRef}
                    getElementAtEvent={e => clickHandler(e)}
                  />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>


        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader >
              <h4 className={classes.cardTitle}>Operation</h4>
            </CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CardBody color="warning">
                  <Bar
                    data={{ ...operationBarData }}
                    options={groupedBarchartOptions}
                    ref={operationBarRef}
                  />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

      </GridContainer>

    </div>
  );
}

const mapSateToProps = state => {
  return {
    questProgress: state.content.current_quest_progress,
    questContent: state.content.contentlist,
  }
}

export default connect(mapSateToProps, null)(Dashboard);
