import * as actionTypes from './actionTypes';
import axiosConfig from "util/axios";
import axios from 'axios';


// --------------Cart-----------------------------

// Add to Cart
export const addCartItem = (cartItem) => {
    return {
        type: actionTypes.ADD_TO_CART,
        cartitem: cartItem
    }
}

export const setCartItems = (cart_Items) => {
    return {
        type: actionTypes.SET_CART_ITEMS,
        cartitems: cart_Items
    }
}
export const addToCart = (cartItem) => {

    return async (dispatch, getState) => {

        const UserId = getState().user.userinfo.userId
        const cartItems = getState().store.cartitems
        
        const found = cartItems.some(el => el.productId === cartItem.productId && el.planId === cartItem.planId);

        if (UserId) {
            if (!found) await dispatch(postCartItems([cartItem]));
        } else {
            if (!found) dispatch(addCartItem(cartItem));
        }
    }
}

export const addItemsToCart = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        const cartItems = getState().store.cartitems
            .filter(data => data.cartId === "")
            .map(data => {
                data.userId = UserId
                return data
            })
        await dispatch(postCartItems(cartItems))
    }
}

export const postCartItems = (cart_items) => {
    return async (dispatch, getState) => {
        const cartItems = getState().store.cartitems
        const UserId = getState().user.userinfo.userId
        await axiosConfig().post('/QuestStore/AddCart', cart_items)
            .then(async response => {
                // await dispatch(fetchCartItems())
                dispatch(setStoreCouponStatus({}))
                dispatch(setStoreCouponCode(""))
            }).catch((error) => {
                console.log(error);
            });
    }
}


// Remove From Cart
export const removeFromCart = (cartItem) => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        cartitem: cartItem
    }
}
export const removeItemFromCart = (cartItem) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        if (UserId) {
            await axiosConfig().post('/QuestStore/DeleteCart', null, {
                params: {
                    cartId: cartItem.cartId,
                }
            }).then(response => {
                dispatch(removeFromCart(cartItem))
            }).catch(function (error) {
                console.log(error);
                // dispatch(fetchSharedDataFailed())
            });

        } else {
            dispatch(removeFromCart(cartItem))
        }

    }
}

// Get Cart Details 
export const fetchCartItems = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        const localCartItems = getState().store.cartitems
        await axiosConfig().get('/QuestStore/GetCartDetails', {
            params: {
                UserId: UserId,
            }
        }).then(response => {
            let allCartItems = Object.values(localCartItems.concat(response.data).reduce((r, o) => {
                r[o.productId] = o;
                return r;
            }, {}));
            dispatch(setCartItems(allCartItems))
        }).catch(function (error) {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}

// --------------Payment Gatway-----------------------------

// Payment Gatway Details
export const setPaymentGatwayDetails = (PG_DATA = {}) => {
    return {
        type: actionTypes.SET_PG_DATA,
        PGData: PG_DATA
    }
}

export const getPaymentGatwayDetails = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        const localCartItems = getState().store.cartitems
        const couponCode = getState().store.couponCode
        const formData = {
            "userId": UserId,
            "paymentMode": "",
            "couponCode": couponCode,
            "cartList": localCartItems.map(data => {
                return {
                    "cartId": data.cartId,
                    "userId": data.userId,
                    "productId": data.productId,
                    "productName": data.productName,
                    "qty": data.qty
                }
            })
        }
        await axiosConfig().post('/QuestStore/PGData', formData).then(response => {
            // let request = response.data.dictValues.reduce(function (result, item) {
            //     result[item.key] = item.value;
            //     return result;
            // }, {});
            dispatch(setPaymentGatwayDetails({ 'orderId': response.data.orderId }))
            dispatch(PaymentGatway(response.data.postUrl, response.data.dictValues))
        }).catch(error => {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}

const PaymentGatway = (url, formData) => {

    return async (dispatch, getState) => {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        formData.forEach(item => {
            var FN = document.createElement("input");
            FN.setAttribute("type", "hidden");
            FN.setAttribute("name", item.key);
            FN.setAttribute("value", item.value);
            form.appendChild(FN)
        });
        document.body.appendChild(form)
        form.submit();
    }
}

// Check Payment Status 
export const setPaymentStatus = (Payment_Status = {}) => {
    return {
        type: actionTypes.SET_PAYMENT_STATUS,
        paymentStatus: Payment_Status
    }
}
export const checkPaymentStatus = (OrderId) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/QuestStore/CheckPaymentStatus', {
            params: {
                PgOrderId: OrderId,
            }
        }).then(response => {
            dispatch(setPaymentStatus(response.data))
        }).catch(function (error) {
            console.log(error);
            // dispatch(fetchSharedDataFailed())
        });
    }
}

// Clear Cart
export const ClearCart = () => {
    return {
        type: actionTypes.CLEAR_CART,
    }
}

//Apply Coupon
export const setStoreCouponStatus = (couponStatus) => {
    return {
        type: actionTypes.SET_STORE_COUPON_STATUS,
        couponStatus: couponStatus
    }
}

export const applyStoreCoupon = (CouponCode) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestStore/ApplyCoupon', {
            params: {
                UserId: UserId,
                CouponCode: CouponCode
            }
        }).then(response => {
            dispatch(setStoreCouponStatus(response.data))
            if (response.data.isSuccess)
                dispatch(setStoreCouponCode(CouponCode))
        }).catch((error) => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

export const setStoreCouponCode = (CouponCode) => {
    return {
        type: actionTypes.SET_STORE_COUPON_CODE,
        couponCode: CouponCode
    }
}

