import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axiosConfig from "util/axios";

//import reducer 
import { useDispatch, useSelector } from 'react-redux';
import * as questActions from 'store/actions/index'

//commonfunctions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";

// @material-ui/core
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';

// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";

//validations
import { checkNull, checkMobileNumber, checkMaxLength } from "util/validations";

//variables error
import { LENGTH_ERROR } from 'variables/general'

// custom style
import styles from "assets/jss/components/views/signinStyle";

// StoreUserData
import { StoreUserData } from 'util/commonfunctions'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const useStyles = makeStyles(styles)

const SigninContainer = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const { textColor } = props;


    const inputUnderlineClasss = classNames({
        [classes.inputUnderline]: true,
        [classes['inputUnderline' + textColor]]: textColor,
    });


    const dispatch = useDispatch();
    const configs = useSelector(state => state.appconfigs.configs)
    const newuser = useSelector(state => state.newuser.registerUser)

    const [state, setState] = useState({
        userName: props.userInfo.userName,
        errorUserName: "",
    });

    const [isMobileValidate, setMobileValidation] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [OTPDialog, setOTPDialog] = useState(false);

    const [verificationCode, setVerificationCode] = useState("");
    const [errorVerificationCode, setErrorVerificationCode] = useState("");

    const [isNewuser, setNewuser] = useState(false)
    const [errorPromoCode, setErrorPromoCode] = useState("")
    const [disabledPromoCode, setDisablPromoCode] = useState(false)


    useEffect(() => {
        dispatch(questActions.setInitialUser())
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const utm_campaign = params.get('utm_campaign');
        dispatch(questActions.setUTMData(params.toString()))
        if (utm_campaign) {
            dispatch(questActions.setPromoCode(utm_campaign))
            setDisablPromoCode(true)
        }
    }, [])

    // ---- click Send OTP --------
    const handelRequestOTP = async () => {
        setLoading(true)
        //Validation
        let validationObject = {};
        checkNull("UserName", state.userName, "Mobile number is required", validationObject);
        checkMobileNumber("UserName", state.userName, "Mobile number is not valid", validationObject);
        if (Object.keys(validationObject).length != 0) {
            setLoading(false)
            setState({
                ...state,
                errorUserName: "",
                ...validationObject
            });
        } else {
            callSendOTP()
        }
    }

    const callSendOTP = async () => {
        //clevertap
        // clevertapEvents('OtpRequest', "{\"Mobilenumber\":\"+91" + state.userName + "\"}");
        await axiosConfig().get('/QuestUser/SendOTP', {
            params: {
                MobileNumber: state.userName
            }
        }).then(response => {
            setLoading(false);
            if (response.data.isSuccess) {

                setAlertType("success")
                setSnackbarMessage("OTP sent successfully!")

                setOTPDialog(true)
                setMobileValidation(true)
                setNewuser(response.data.isNewuser)
                dispatch(questActions.pushLeadInfo([
                    { 'attribute': 'mobile', 'value': state.userName },
                    { 'attribute': 'request_otp', 'value': "success" }
                ]))
                //clevertap'
                if (response.data.isNewuser)
                    clevertapEvents('OtpRequest', "{\"Mobilenumber\":\"+91" + state.userName + "\"}");
            } else {
                setAlertType("danger")
                setAlertMessage(response.data.message)
            }

        }).catch(error => {
            setLoading(false);
            setAlertType("error")
            if (error.response)
                setAlertMessage(error.response.data.error)
            else
                setAlertMessage(error.message)
        });
    }


    /*----------------------- Open URL --------------------*/
    const onOpenURL = (URL) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.openNewUrl("", URL)
        } else if (typeof openNewUrl !== 'undefined') {
            window.openNewUrl("", URL)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('openNewUrl', "", URL);
        } else {
            window.open(URL, "_blank")
        }
    }

    /*----------------------on Change Mobile Number --------------------*/
    const onChangeMobileNumber = (e) => {
        e.preventDefault();
        setState({ ...state, userName: e.target.value })
        setMobileValidation(false)
    }

    /*----------------------on Edit --------------------*/
    const onEdit = () => {
        setVerificationCode("")
        setErrorVerificationCode("")
        setErrorPromoCode("")
        setMobileValidation(false)

    }


    /*----------------------End Adornment --------------------*/
    const EndAdornment = () => {
        return (
            <React.Fragment>
                {isMobileValidate
                    ? <Button
                        // disabled={state.userName.length === 10 ? false : true}
                        size="xs" simple color="white"
                        onClick={onEdit}
                    >
                        Edit
                    </Button>
                    : <Button
                        disabled={state.userName.length === 10 ? false : true}
                        size="xs" simple color="white"
                        onClick={handelRequestOTP}
                    >
                        Get OTP
                    </Button>
                }

            </React.Fragment>
        )
    }

    /*---------------------- handel Login  --------------------*/
    const handelLogin = async (promoCode) => {
        localStorage.setItem('PASSWORD_TYPE', 'OTP'); // use for Change Password

        let validationObject = {};
        setErrorVerificationCode("")
        setErrorPromoCode("")

        checkNull("verificationCode", verificationCode, "Verification Code is required", validationObject);
        checkMaxLength("verificationCode", verificationCode, 10, LENGTH_ERROR(10), validationObject);

        if (isNewuser) {
            // checkNull("PromoCode", promoCode, "Name is required", validationObject);
            checkMaxLength("PromoCode", promoCode, 10, LENGTH_ERROR(10), validationObject);
        }

        if (Object.keys(validationObject).length != 0) {
            setErrorVerificationCode(validationObject.errorverificationCode)
            setErrorPromoCode(validationObject.errorPromoCode)
        } else {
            handelVerifyOTPUser(promoCode)
        }
    }

    const handelVerifyOTPUser = async (promoCode="") => {
        setLoading(true)
        await axiosConfig().post('/QuestUser/VerifyOTPUser', {
            OTP: verificationCode,
            MobileNumber: state.userName,
            PromoCode:promoCode
        }).then(async response => {


            if (response.data.isSuccess) {

                dispatch(questActions.pushLeadInfo([
                    { 'attribute': 'mobile', 'value': state.userName },
                    { 'attribute': 'verify_otp', 'value': "success" }
                ]))

                //Android
                if (typeof window.Android !== 'undefined') {
                    window.Android.onRemenberMeClicked(state.userName, verificationCode, false);
                }
                //IOS
                if (typeof onRemenberMeClicked !== 'undefined') {
                    window.onRemenberMeClicked(state.userName, verificationCode, false);
                }
                if (typeof window.flutter_inappwebview !== 'undefined') {
                    window.flutter_inappwebview.callHandler('onRemenberMeClicked', state.userName, verificationCode, false);
                }

                await StoreUserData(state.userName, verificationCode, true)
                sessionStorage.setItem('token', response.data.token);

                //set User Type
                //dispatch(questActions.setUserType(response.data.userType))

                if (isNewuser || response.data.userType === "NewUser") {
                    dispatch(questActions.setUsername(state.userName))
                    dispatch(questActions.setUserType("NewUser"))
                    //clevertap
                    const eventData = JSON.stringify({
                        'Mobilenumber': "+91" + state.userName,
                        'EmailId': "",
                        'UserName': state.userName
                    });
                    clevertapEvents('Registration', eventData);

                    //facebook tracking
                    const EventProperty = JSON.stringify({
                        'fb_registration_method': 'mobile'
                    })
                    facebookTracking('fb_mobile_complete_registration', EventProperty)

                    props.handleNext(response.data.userId)

                    // history.push({ pathname: '/boardgrade', state: { userId: response.data.userId } });

                } else {
                    await axiosConfig().get('/QuestUser/GetCurrentUser').then(async response => {
                        await dispatch(questActions.setUserInfo(response.data))
                        if (response.data.userQuestList.length > 0) {
                            dispatch(questActions.initUserQuestList(props.isInitCurrentQuestAndContentList)).then(() => {
                                setLoading(false)
                                history.push('/home')
                            })
                        } else if (response.data.promoCode) {
                            const isRaymondUser = await questActions.verifyRaymondStoreCode(response.data.promoCode)
                            if (isRaymondUser) {
                                setLoading(false)
                                history.push('/raymond-products')
                            }
                            else {
                                setLoading(false);
                                response.data.showDashboard ? history.push('/products') : history.push('/home')
                            }
                        }
                        else if (response.data.showDashboard) {
                            setLoading(false)
                            history.push('/products')
                        } else {
                            setLoading(false)
                            history.push('/home')
                        }
                    }).catch(function (error) {
                        setLoading(false)
                        dispatch(questActions.fetchUserInfoFailed())
                    });
                }



            } else {
                setLoading(false)
                setAlertType("error")
                setSnackbarMessage(response.data.message)
            }

        }).catch(error => {
            setLoading(false)
            if (error.response) {
                setAlertType("error")
                setSnackbarMessage(error.response.data.error)
            }
        });
    }


    return (
        <React.Fragment>
            {isLoading
                ? < LinearProgress color="primary" style={{ position: 'absolute', width: '95%', left: 10, right: 10 }} />
                : null
            }

            {/* ---AlertDialog--- */}
            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => setAlertMessage("") }
                ]}
            />

            <div className={classes.paper}>
                <div className={classes.form}>


                    <FormControl margin="normal" required fullWidth>
                        <InputLabel style={{ color: props.textColor }}>Enter mobile number</InputLabel>
                        <Input
                            type="number"
                            className={classes.number}
                            classes={{ underline: inputUnderlineClasss }}
                            name="Mobile Number"
                            autoFocus={true}
                            disabled={(isLoading || isMobileValidate) ? true : false}
                            value={state.userName}
                            onChange={onChangeMobileNumber}
                            onInput={(e) => {
                                e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
                            }}
                            endAdornment={<EndAdornment />}
                        />
                        <FormHelperText className={classes.labelRootError} >{state.errorUserName}</FormHelperText>
                    </FormControl>


                    <FormControl margin="normal" required fullWidth>
                        <InputLabel style={{ color: props.textColor }}>Enter OTP</InputLabel>
                        <Input
                            className={classes.number}
                            classes={{ underline: inputUnderlineClasss }}
                            disabled={(isLoading || !isMobileValidate) ? true : false}
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            onInput={(e) => {
                                e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : ""
                            }}
                            type="number"
                        />
                        <FormHelperText className={classes.labelRootError} >{errorVerificationCode}</FormHelperText>
                    </FormControl>

                    {isNewuser
                        ? <FormControl margin="normal" fullWidth>
                            <InputLabel style={{ color: props.textColor }}>Enter Code</InputLabel>
                            <Input
                                // className={classes.number}
                                classes={{ underline: inputUnderlineClasss }}
                                disabled={(isLoading || !isMobileValidate || disabledPromoCode) ? true : false}
                                value={newuser.promoCode}
                                onChange={(e) => {
                                    dispatch(questActions.setPromoCode(e.target.value))
                                }}
                                inputProps={{ maxLength: 10 }}
                            />
                            <FormHelperText className={classes.labelRootError} >{errorPromoCode}</FormHelperText>
                        </FormControl>
                        : null
                    }

                    <Button
                        //disabled={(isLoading || !isMobileValidate) ? true : false}
                        round
                        fullWidth
                        variant="contained"
                        color={textColor === "white" ? "white" : "primary"}
                        className={classes.submit}
                        onClick={() => handelLogin(newuser.promoCode)}>
                        Login
                    </Button>

                    {/* <div style={{ marginTop: 8 }}>
                        <Divider />
                    </div> */}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ color: props.textColor }}>
                            <Typography
                                variant="caption"
                                paragraph
                                display="inline"
                            >
                                By Clicking Login,You Agree to the SESL
                            </Typography>
                            <br></br>
                            <div className={classes.hyperText} style={{ color: props.textColor }}>
                                <samp className={classes.hyperText} style={{ color: "#0000FF", cursor: 'pointer', textDecoration: 'underline' }} onClick={() => onOpenURL(configs.tcUrl)} > Privacy Policy </samp>
                                and
                                <samp className={classes.hyperText} style={{ color: "#0000FF", cursor: 'pointer', textDecoration: 'underline' }} onClick={() => onOpenURL(configs.tcUrl)} > Terms and Conditions</samp>
                            </div>
                        </GridItem>
                    </GridContainer>

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={snackbarMessage ? true : false}
                        autoHideDuration={3000}
                        onClose={() => setSnackbarMessage("")}
                        ContentProps={{ 'aria-errormessage': 'message-id', }}
                        message={<span id="message-id" >{snackbarMessage}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="secondary"
                                className={classes.close}
                                onClick={() => setSnackbarMessage("")}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>


    );
}

SigninContainer.defaultProps = {
    textColor: "black",
    userInfo: {
        userName: '',
        remember: true
    },
    onLogin: () => { },
    onExplore: null,
    onSignUp: null,
    isInitCurrentQuestAndContentList: true
}

SigninContainer.propTypes = {
    color: PropTypes.oneOf(["white", "black"]),
};

export default SigninContainer;
