import React from 'react';


//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
// Views
import ContentCard from 'components/Views/Tasks/Topics/ContentCard'

// img
import Video from "assets/img/task/Video.png";
import Assessment from "assets/img/task/Assessment.png";
import pdforebook from "assets/img/task/pdforebook.png";
import Activity from "assets/img/task/Activity.png";

// styles
import useStyles from "assets/jss/components/views/taskStyle";


const TopicCards = (props) => {

    const classes = useStyles();

    // 01	AV Uploaded
    // 02	AV External Link
    // 03	Youtube Link

    // 04	PDF Uploaded
    // 05	PDF External Link

    // 06	HTML Created
    // 07	HTML External Link

    // 08	Objective Test
    // 09	Subjective Test

    let Content = null;
    switch (props.contentTypeCode) {
        case ('01'):
            Content = <ContentCard content={{ ...props }} imgPath={Video} setContentType={props.setContentType} />
            break;
        case ('02'):
            Content = <ContentCard content={{ ...props }} imgPath={Video} setContentType={props.setContentType} />;
            break;
        case ('03'):
            Content = <ContentCard content={{ ...props }} imgPath={Video} setContentType={props.setContentType} />
            break;
        case ('04'):
            Content = <ContentCard content={{ ...props }} imgPath={pdforebook} link={'/pdf'} />
            break;
        case ('05'):
            Content = <ContentCard content={{ ...props }} imgPath={pdforebook} link={'/pdf'} />
            break;
        case ('06'):
            Content = <ContentCard content={{ ...props }} imgPath={Activity} link={'/contentviewer'} />
            break;
        case ('07'):
            Content = <ContentCard content={{ ...props }} imgPath={Activity} link={'/viewer'} />
            break;
        case ('08'):
            // if (props.isDynamicAssessment)
                Content = <ContentCard content={{ ...props }} imgPath={Assessment} link={'/instruction'} />
            // else
            //     Content = <ContentCard content={{ ...props }} imgPath={Assessment} link={'/online-test'} />
            break;
        case ('09'):
            Content = <ContentCard content={{ ...props }} imgPath={Assessment} link={'/instruction'} />
            break;
        case ('10'):
            Content = <ContentCard content={{ ...props }} imgPath={Activity} link={'/activity'} />
            break;
        default:
            Content = null;
    }
    return (
        <React.Fragment>
            {Content}
        </React.Fragment>
    )
}




export default TopicCards;