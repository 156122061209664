import React, { useEffect } from "react";
// @material-ui/core
// import { makeStyles, withStyles } from "@material-ui/core/styles";
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// core components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
import PDFView from 'components/CustomComponents/PDFView/PDFView'

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";
// styles
// import styles from "assets/jss/components/views/PDFViewerStyle";
// const useStyles = makeStyles(styles);



const PDFViewer = (props) => {

    useEffect(() => {
        let accessTime = 0;
        const timer = setInterval(() => {
            accessTime++
        }, 1000);
        return () => {
            if (accessTime > 10) {
                const BODY_DATA = {
                    "missionActivityId": "", // Don't set this
                    "userId": props.userInfo.userId,
                    "locationId": props.userInfo.locationId,
                    "groupId": props.userInfo.groupId,
                    "questId": props.quest.questId,
                    "contentId": props.content.contentId,
                    "contentTypeCode": props.content.contentTypeCode,
                    "accessDate": "" + CURRENT_DATE_TIME(),
                    "accessDuration": "" + accessTime,
                    "missionActivitySummaryId": "" // Don't set this
                }
                props.onPostContentAccess(BODY_DATA,props.content)
            }

            clearTimeout(timer)
        };
    }, []);

    // const classes = useStyles();
    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.content.contentDisplayName} />

            {/*-----page-container----- */}
            <div className='pdf-container'>
                <PDFView
                    fileurl={props.content.fileUrl}
                    password="" fileName={props.content.contentDisplayName}
                    contentId={props.content.contentId}
                />
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPostContentAccess: (accessData, content) => dispatch(questActions.postContentAccess(accessData, content)), //also update update content,current_cuntent and quest_progress
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewer);