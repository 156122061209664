import React, { useState, useEffect, useRef } from 'react';
//Redux
import { connect, useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'

import CssBaseline from '@material-ui/core/CssBaseline';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
import VideoViewer from 'components/Views/ContentViewer/VideoViewer/VideoViewer'
import AutoPlaySwipeableViews from 'components/Views/Tasks/Topics/TaskSwipeableViews'
import TopicAccordion from 'components/Views/Tasks/Topics/TopicAccordion'
// // style
import useStyles from 'assets/jss/components/views/topicsContainerStyle'
//Common functions
// import { clevertapEvents, facebookTracking } from "util/commonfunctions";


function SwipeableTextMobileStepper(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const elementRef = useRef();

    const mission = useSelector(state => state.content.current_mission)
    const quest = useSelector(state => state.quest.current_quest)
    const content = useSelector(state => state.content.contentlist);
    const current_content = useSelector(state => state.content.current_content);
    const operation = useSelector(state => state.content.current_operation)
    const questContentsList = useSelector(state => state.content.contentlist.add.questContents.filter((questContent) => (operation.operationId === questContent.operationId) && questContent["taskId"] === undefined))
    const taskList = useSelector(state => state.content.contentlist.add.tasks.filter((task) => operation.operationId === task.operationId))
    const introductionExpanded = questContentsList.reduce((boolean, next) => next.isExpanded || boolean, false)

    const [isVideo, setIsVideo] = React.useState(false);
    const [ScreenOrientation, setScreenOrientation] = useState('portrait')

    const handleChange = (taskId, panel) => (event, newExpanded) => {
        dispatch(questActions.setExpandedFlag(taskId, newExpanded))
    };

    useEffect(() => {
        if (questContentsList.length) {
            // const contentTypeCode = questContentsList[0].contentTypeCode;
            // if ((contentTypeCode === '01' || contentTypeCode === '02' || contentTypeCode === '03') && introductionExpanded) {
            //     clevertapfacebook(contentTypeCode)
            //     dispatch(questActions.setCurrentContent(questContentsList[0]))
            //     setIsVideo(true)
            // }
        } else if (taskList.length) {
            dispatch(questActions.setExpandedFlag(taskList[0].taskId, true))
            // const contentList = content.add.questContents.filter(questContent => taskList[0].taskId === questContent.taskId)
            // if (contentList.length) {
            //     const contentTypeCode = contentList[0].contentTypeCode;
            //     if (contentTypeCode === '01' || contentTypeCode === '02' || contentTypeCode === '03') {
            //         clevertapfacebook(contentTypeCode)
            //         dispatch(questActions.setCurrentContent(contentList[0]))
            //         setIsVideo(true)
            //     }
            // }
        }
        // else {
        //     dispatch(questActions.setCurrentContent({}))
        //     setIsVideo(false)
        // }
        dispatch(questActions.setCurrentContent({}))
        setIsVideo(false)
    }, [operation.operationId])

    // const clevertapfacebook = (contentTypeCode) => {
    //     //clevertap
    //     const eventData = JSON.stringify({
    //         'QuestName': quest.questName,
    //         'MisssionName': mission.missionDisplayName,
    //         'OperationName': operation.operationDisplayName,
    //         'ContentType': contentTypeCode
    //     });
    //     clevertapEvents('ContentView', eventData);
    //     //facebook tracking
    //     const EventProperty = JSON.stringify({
    //         'fb_content_type': contentTypeCode,
    //         'fb_content_id': mission.missionName,
    //         'fb_currency': ''
    //     })
    //     console.log(EventProperty);
    //     facebookTracking('fb_mobile_content_view', EventProperty)
    // }

    const handleChangeTask = (contentType) => {
        if (typeof onRemenberMeClicked === 'undefined')
            setIsVideo(contentType)
        else
            props.history.push('/player')
        elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }


    return (
        <div className={classes.root}>

            {ScreenOrientation === "portrait"
                ? <PageNavigation logoText={operation.operationDisplayName} /> //back={'/task'}
                : <CssBaseline />
            }


            <div className="page-container-5030 background-tasks">
                <GridContainer className={classes.listGridContainer}>
                    <GridItem xs={12} sm={12} md={6} >
                        <div ref={elementRef}>
                            {typeof onRemenberMeClicked === 'undefined' && (current_content.contentTypeCode === '01' || current_content.contentTypeCode === '02' || current_content.contentTypeCode === '03')
                                ? <VideoViewer
                                    orientation={ScreenOrientation}
                                    isVideo={isVideo}
                                    onChangeOrientation={setScreenOrientation}
                                />

                                : null
                            }
                        </div>

                        <div className={classes.accordionDiv} style={{ height: '50vh', paddingBottom: 10, display: ScreenOrientation === 'portrait' ? 'unset' : 'none' }}>
                            {questContentsList.length
                                ? <TopicAccordion
                                    expanded={introductionExpanded}
                                    onChange={handleChange('')}
                                    heading='Introduction'
                                    accordionDetails={<AutoPlaySwipeableViews
                                        task={""}
                                        topics={questContentsList}
                                        setContentType={type => (handleChangeTask(type))}
                                    />}
                                />
                                : null}
                            {taskList.length
                                ? taskList.map((current_task, indx) =>
                                    <TopicAccordion
                                        key={`${current_task.operationId}${current_task.taskId}`}
                                        expanded={current_task.isExpanded}
                                        onChange={handleChange(current_task.taskId)}
                                        heading={current_task.taskDisplayName}
                                        accordionDetails={<AutoPlaySwipeableViews
                                            task={current_task}
                                            setContentType={type => (handleChangeTask(type))}
                                            topics={content.add.questContents.filter((questContent) => current_task.taskId === questContent.taskId)} />}
                                    />

                                ) : null
                            }
                        </div>
                    </GridItem>
                </GridContainer>
            </div >
        </div>
    );
}

const mapStateToProps = state => {
    return {
        content: state.content.contentlist,
        operation: state.content.current_operation
    }
}

export default connect(mapStateToProps, null)(SwipeableTextMobileStepper);
